import React, { useEffect, useState } from "react";
import { Container, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import HiRobot from "../assets/images/HiRobot.png";
import Footer2 from "../components/Footer2";
import OtpInput from "react-otp-input";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  banner_button: {
    padding: "13px 35px  !important",
    [theme.breakpoints.down("md")]: {
      padding: "2px 25px  !important",
    },
  },

  h6: {
    margin: "0px 0 15px 0 !important",
    textAlign: "start",
  },

  font_color: {
    color: "#21409A",
    marginLeft: 10,
  },

  flex_wrapper: {
    marginTop: -53,
    padding: "15px 100px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  inner_wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  header_nav_item: {
    fontSize: 20,
    fontWeight: 600,
    padding: "0px 15px 3px 15px",
  },
  banner_button2: {
    padding: "13px 35px  !important",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 30px  !important",
      marginTop: 10,
      display: "none",
    },
  },
  opt_input_holder: {
    width: "430px",
    margin: "auto",
    "& input:focus": {
      outline: "none !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  newInputStyle: {
    background: "none",
    minWidth: "36px",
    minHeight: "36px",
    fontSize: "36px",
    // textAlign: "center !important",
    borderRadius: "0px",
    borderBottom: "2px solid #000",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    [theme.breakpoints.down("md")]: {
      minWidth: "20px",
      minHeight: "20px",
      fontSize: "20px",
    },
  },

  emergencyTtitle: {
    fontSize: "40px",
    fontWeight: 500,
    margin: "30px 0px 50px",
    textAlign: "center",
    fontFamily: "'Roboto', sans-serif",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      margin: "20px 0px 30px",
    },
  },
  topCardHolderOtherView: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  topCardHolderMobileView: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));

const Emergency = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [myOTP, setMyOTP] = useState({ otp: "" });
  const [loading, setLoading] = useState(false);
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const handleChange2 = (otp) => {
    setMyOTP({ otp });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/private/childcare/emergency-code/${myOTP.otp}/attendees`,
        method: "get",
      });

      if (response.status >= 200 && response.status < 300) {
        history.push("/attended-list", { data: response.data });
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      handleSnakbarOpen(error.response.data.errors.toString(), "error");
    }
    setLoading(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={classes.flex_wrapper}>
        <div className={classes.inner_wrapper}>
          <div>
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>
          <div>
            <Typography className={classes.header_nav_item}>
              Emergency
            </Typography>
          </div>
        </div>
        <div>
          <Link to="/">
            <Button
              variant="outlined"
              color="secondary"
              className={`${classes.banner_button2} button`}
            >
              Go to KinderCubby
            </Button>
          </Link>
        </div>
      </div>
      <Container maxWidth="lg">
        <p className={classes.emergencyTtitle}>
          Emergency Response Support Portal
        </p>
        <div style={{ background: "#e9ecef", padding: "60px 36px" }}>
          <div className={classes.topCardHolderOtherView}>
            <img
              width="200px"
              src={HiRobot}
              alt=""
              style={{ display: "block", margin: "auto auto 20px auto" }}
            />
            <Typography
              variant="h6"
              style={{ textAlign: "left", fontWeight: 300 }}
            >
              Welcome to our Emergency Response Support Portal. As a critical
              part of our safety and security measures, this portal is
              specifically tailored to aid emergency response teams like yours
              during urgent situations.
            </Typography>
            <hr style={{ margin: "40px 0px" }} />
          </div>
          <div>
            <Typography variant="h4" className={classes.font_color}>
              Enter Emergency Code Below
            </Typography>

            <form onSubmit={onSubmit} className={classes.opt_input_holder}>
              <OtpInput
                value={myOTP.otp}
                onChange={handleChange2}
                numInputs={6}
                // inputType={"number"}
                // isInputNum={true}
                inputStyle={classes.newInputStyle}
                containerStyle={{ justifyContent: "space-between" }}
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} />}
              />
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  disabled={loading}
                  color="primary"
                  size="small"
                  style={{ marginTop: 45, minHeight: "50px" }}
                  className={`${classes.banner_button} button`}
                  type="submit"
                >
                  {loading === false && "Access Now"}
                  <PulseLoader
                    color={"#EE6741"}
                    loading={loading}
                    size={10}
                    speedMultiplier={0.5}
                  />{" "}
                </Button>
              </div>
            </form>
            <hr style={{ margin: "40px 0px" }} />
          </div>

          <div className={classes.topCardHolderMobileView}>
            <img
              width="200px"
              src={HiRobot}
              alt=""
              style={{ display: "block", margin: "auto auto 20px auto" }}
            />
            <Typography
              variant="h6"
              style={{ textAlign: "left", fontWeight: 300 }}
            >
              Welcome to our Emergency Response Support Portal. As a critical
              part of our safety and security measures, this portal is
              specifically tailored to aid emergency response teams like yours
              during urgent situations.
            </Typography>
            <hr style={{ margin: "40px 0px" }} />
          </div>

          <Typography variant="body1">
            Every daycare center in our network is assigned a unique
            8-alphanumeric emergency code. This code is strictly accessible to
            the daycare manager and teachers through our Daycare Manager Portal
            and teacher app. When you are responding to an emergency at one of
            our locations, you may need to request this code.
          </Typography>
          <hr style={{ margin: "40px 0px" }} />
          <Typography variant="body1">
            To access the necessary information, please input the provided
            emergency code into the field marked on this page. Upon confirmation
            of the code, you will gain immediate access to a comprehensive list
            of children and teachers present at the daycare on the day of the
            incident. This includes crucial information such as attendance
            records, home addresses, and parental contact details.
          </Typography>
          <hr style={{ margin: "40px 0px" }} />
          <Typography variant="body1">
            Our aim with this portal is to facilitate swift and effective
            responses to emergencies by ensuring immediate access to vital
            information. We deeply appreciate your work in protecting our
            community and ask that you only use this service in active emergency
            situations to safeguard the privacy of our families.
          </Typography>
        </div>
      </Container>
      <br />
      <Footer2 />
    </>
  );
};

export default Emergency;
