import React, { useEffect, useRef, useState } from "react";
import { Container, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import Footer2 from "../components/Footer2";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { useLocation } from "react-router-dom";
import ReactToPdf from "react-to-pdf";
// import ReactToPrint from "react-to-print";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  banner_button: {
    padding: "5px 35px  !important",
    display: "block",
    marginLeft: "auto",
    fontSize: "16px !important",
    [theme.breakpoints.down("md")]: {
      padding: "2px 25px  !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "auto",
      marginLeft: "auto",
    },
  },

  h6: {
    margin: "0px 0 15px 0 !important",
    textAlign: "start",
  },

  flex_wrapper: {
    marginTop: -53,
    padding: "15px 100px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  inner_wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  header_nav_item: {
    fontSize: 20,
    fontWeight: 600,
    padding: "0px 15px 3px 15px",
  },
  banner_button2: {
    padding: "13px 35px  !important",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 30px  !important",
      marginTop: 10,
      display: "none",
    },
  },

  emergencyTtitle: {
    fontSize: "40px",
    fontWeight: 500,
    margin: "30px 0px 30px",
    textAlign: "center",
    fontFamily: "'Roboto', sans-serif",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      margin: "20px 0px 30px",
    },
  },
  emergencyTtitleForPdf: {
    fontSize: "25px",
    fontWeight: 500,
    margin: "0px 0px 30px",
    textAlign: "center",
    fontFamily: "'Roboto', sans-serif",
  },
  tableStyle: {
    border: "1px solid #ddd",

    "& th": {
      fontSize: "16px",
      fontWeight: 900,
      borderRight: "1px solid #ddd",
    },
    "& td": {
      fontSize: "14px",
      fontWeight: 600,
      borderRight: "1px solid #ddd",
    },
    "& tbody tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    // "& td:last-child": {
    //   borderRight: "none",
    // },
    "& .MuiTableRow-root": {
      verticalAlign: "top",
    },
    "& ul": {
      margin: "0px 0px 10px 30px",
    },
    "& li": {
      fontSize: "12px",
      fontWeight: 600,
    },
  },

  tableTitle: {
    fontSize: "32px !important",
    fontWeight: "800 !important",
    color: "#1c1c1c",
    textAlign: "center",
    padding: "40px 0px !important",
    fontFamily: "'Roboto', sans-serif",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px !important",
    },
  },
  tableTitleForpdf: {
    fontSize: "32px !important",
    fontWeight: "800 !important",
    color: "#1c1c1c",
    textAlign: "center",
    padding: "40px 0px !important",
    fontFamily: "'Roboto', sans-serif",
  },
  tableContainerStyle: {
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
  },
  childCareNameBox: {
    backgroundColor: "black",
    padding: "10px 0px",
    margin: "30px 0px 0px",
    printColorAdjust: "exact",
  },
}));

const AttendedList = () => {
  const classes = useStyles();
  const location = useLocation();
  const ref = React.createRef();

  const countAttendedKids = () => {
    let totalAttenedKids = location?.state?.data?.kids?.filter(
      (res) => res.attendanceType === "DroppedOff"
    );

    let totalKids = "";
    if (totalAttenedKids.length < 10) {
      totalKids = "0" + totalAttenedKids.length;
    } else {
      totalKids = totalAttenedKids.length;
    }
    return totalKids;
  };

  function printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();
    // document.body.innerHTML = originalContents;
    // window.location.reload();
    // setReload(!reload);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="noprint">
        <div className={`${classes.flex_wrapper} `}>
          <div className={classes.inner_wrapper}>
            <div>
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div>
              <Typography className={classes.header_nav_item}>
                Attended List
              </Typography>
            </div>
          </div>
          <div>
            <Link to="/">
              <Button
                variant="outlined"
                color="secondary"
                className={`${classes.banner_button2} button`}
              >
                Go to KinderCubby
              </Button>
            </Link>
          </div>
        </div>
        <Container maxWidth="lg">
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={`${classes.banner_button}`}
            disableElevation
            // onClick={() => printDiv("printableArea")}
            onClick={() => window.print()}
            style={{ display: "block", marginLeft: "auto" }}
          >
            Download List
          </Button>
          <div>
            <p className={classes.emergencyTtitle}>
              Emergency Contact and Information Center
            </p>
            <div
              style={{
                background: "#1c1c1c",
                padding: "10px 0px",
                margin: "30px 0px 0px",
              }}
            >
              <Typography variant="h6" style={{ margin: "0px", color: "#fff" }}>
                <b>
                  {location?.state?.data?.daycare?.name} | Date:{" "}
                  {moment(new Date()).format("MMMM D, YYYY")}
                </b>
              </Typography>
              <Typography
                variant="body2"
                style={{ margin: "0px", textAlign: "center", color: "#fff" }}
              >
                {location?.state?.data?.daycare?.street},&nbsp;
                {location?.state?.data?.daycare?.city},&nbsp;
                {location?.state?.data?.daycare?.state}&nbsp;-
                {location?.state?.data?.daycare?.postalCode},&nbsp;
                {location?.state?.data?.daycare?.country} | Contact No:{" "}
                {location?.state?.data?.daycare?.contactPersonPhone} | Email:{" "}
                {location?.state?.data?.daycare?.contactPersonEmail}
              </Typography>
            </div>

            <div
              className={classes.tableTitle}
              style={{ borderRight: "1px solid #ddd", borderLeft: "1px solid #ddd" }}
            >
              No. of Current Attendees : {countAttendedKids()}
            </div>

            <TableContainer className={classes.tableContainerStyle}>
              <Table className={classes.tableStyle} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Photo</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>DOB </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      Last Known Status
                    </TableCell>
                    <TableCell>Medical</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      Emergency Contact
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {location?.state?.data?.kids?.map((item, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <img
                          src={
                            item?.photo.length > 0
                              ? item?.photo
                              : "/image/NoImage.jpg"
                          }
                          alt=""
                          style={{
                            width: "260px",
                            // height: "120px",
                            // borderRadius: "8px",
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ minWidth: "200px" }}>
                        {" "}
                        {item?.firstName}&nbsp; {item?.lastName}
                      </TableCell>
                      <TableCell> {item?.gender}</TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {moment(item?.dateOfBirth).format("MMMM D, YYYY")}
                      </TableCell>
                      <TableCell>
                        {item?.attendanceType} at {item?.at}
                      </TableCell>
                      <TableCell>
                        Medications
                        <ul>
                          {item?.health?.medications?.map((row, i) => (
                            <li key={i}>{row}</li>
                          ))}
                        </ul>
                        Allergies
                        <ul>
                          {item?.health?.allergies?.map((row, i) => (
                            <li key={i}>{row}</li>
                          ))}
                        </ul>
                      </TableCell>
                      <TableCell>
                        {item?.parent?.firstName} {item?.parent?.lastName}
                        <br />
                        Phone: {item?.parent?.phoneNumber}
                        <br />
                        Email: {item?.parent?.email}
                        <br />
                        Address:&nbsp;
                        {item?.parent?.street},&nbsp;
                        {item?.parent?.locality},&nbsp;
                        {item?.parent?.region}&nbsp;-
                        {item?.parent?.postalCode},&nbsp;
                        {item?.parent?.country}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <br />
          </div>
        </Container>

        <br />
        <Footer2 />
      </div>

      <div style={{ display: "none" }} className="printMe">
        <div
          // ref={componentRef}
          style={{ width: "11.9in", margin: 0, padding: 20 }}
          // style={{ width: "11.9in", margin: 0, padding: 0 }}
          ref={ref}
          id="printableArea"
        >
          <p className={classes.emergencyTtitleForPdf}>
            Emergency Contact and Information Center
          </p>
          <div
            style={{
              background: "black",
              padding: "10px 0px",
              margin: "30px 0px 0px",
            }}
            className={classes.childCareNameBox}
          >
            <Typography
              variant="h6"
              style={{ margin: "0px", color: "#fff", fontSize: "20px" }}
            >
              <b>
                {location?.state?.data?.daycare?.name} | Date:{" "}
                {moment(new Date()).format("MMMM D, YYYY")}
              </b>
            </Typography>
            <Typography
              variant="body2"
              style={{ margin: "0px", textAlign: "center", color: "#fff" }}
            >
              {location?.state?.data?.daycare?.street},&nbsp;
              {location?.state?.data?.daycare?.city},&nbsp;
              {location?.state?.data?.daycare?.state}&nbsp;-
              {location?.state?.data?.daycare?.postalCode},&nbsp;
              {location?.state?.data?.daycare?.country} | Contact No:{" "}
              {location?.state?.data?.daycare?.contactPersonPhone} | Email:{" "}
              {location?.state?.data?.daycare?.contactPersonEmail}
            </Typography>
          </div>
          <div
            className={classes.tableTitleForpdf}
            style={{ borderRight: "1px solid #ddd", borderLeft: "1px solid #ddd" }}
          >
            No. of Current Attendees : {countAttendedKids()}
          </div>
          {/* <TableContainer> */}
          <Table className={classes.tableStyle} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Photo</TableCell>
                <TableCell style={{ minWidth: "220px" }}>
                  Kids Information
                </TableCell>

                {/* <TableCell style={{ whiteSpace: "nowrap" }}>
                  Last Known Status
                </TableCell> */}
                <TableCell>Medical</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Emergency Contact
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {location?.state?.data?.kids?.map((item, i) => (
                <TableRow key={i}>
                  <TableCell style={{ minWidth: "200px" }}>
                    <img
                      src={
                        item?.photo.length > 0
                          ? item?.photo
                          : "/image/NoImage.jpg"
                      }
                      alt=""
                      style={{
                        width: "200px",
                        // height: "120px",
                        // borderRadius: "8px",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    Name: {item?.firstName}&nbsp; {item?.lastName}
                    <br />
                    Gender : {item?.gender}
                    <br />
                    DOB: {moment(item?.dateOfBirth).format("MMMM D, YYYY")}
                    <br />
                    Status: {item?.attendanceType} at {item?.at}
                  </TableCell>

                  {/* <TableCell>
                    {item?.attendanceType} at {item?.at}
                  </TableCell> */}
                  <TableCell>
                    Medications
                    <ul>
                      {item?.health?.medications?.map((row, i) => (
                        <li key={i}>{row}</li>
                      ))}
                    </ul>
                    Allergies
                    <ul>
                      {item?.health?.allergies?.map((row, i) => (
                        <li key={i}>{row}</li>
                      ))}
                    </ul>
                  </TableCell>
                  <TableCell>
                    {item?.parent?.firstName} {item?.parent?.lastName}
                    <br />
                    Phone: {item?.parent?.phoneNumber}
                    <br />
                    Email: {item?.parent?.email}
                    <br />
                    Address:&nbsp;
                    {item?.parent?.street},&nbsp;
                    {item?.parent?.locality},&nbsp;
                    {item?.parent?.region}&nbsp;-
                    {item?.parent?.postalCode},&nbsp;
                    {item?.parent?.country}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* </TableContainer> */}

          <br />
        </div>
      </div>
    </>
  );
};

export default AttendedList;
