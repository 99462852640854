
import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ActivityFeed2x from "./assets/images/ActivityFeed2x.png";
import ContactlessCheckIn2x from "./assets/images/ContactlessCheckIn2x.png";
import OnlinePaymentP12x from "./assets/images/OnlinePaymentP12x.png";
import { Grid, IconButton, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from "@material-ui/core/styles";
import OtpInput from 'react-otp-input';
const useStyles = makeStyles((theme) => ({
  slickCardHolder: {
    height: "500px",
    // border: "1px solid",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  slickCardImage: {
    background: "#fff",
    padding: "30px",
    boxShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
  },
}));
const Test = () => {
  const classes = useStyles();
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "", cursor: "auto" }}
      >
        <IconButton
          onClick={onClick}
          style={{
            background: "#fff",
            border: "1px solid #ddd",
            position: "relative",
            top: -65,
          }}
        >
          <ArrowForwardIosIcon style={{ color: "#EE6741" }} />
        </IconButton>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "", cursor: "auto" }}
      >
        <IconButton
          onClick={onClick}
          style={{
            background: "#fff",
            border: "1px solid #ddd",
            position: "relative",
            top: -65,
            left: -38,
          }}
        >
          <ArrowBackIosIcon
            style={{ color: "#EE6741", position: "relative", left: 5 }}
          />
        </IconButton>
      </div>
    );
  }
  const settings = {
    centerMode: true,
    centerPadding: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    // dots: true,
    // autoplay: true,
    // centerPadding: "60px",
    // autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // responsive: [
    //   {
    //     breakpoint: 767,
    //     settings: {
    //       arrows: false,
    //       dots: true,
    //       centerMode: true,
    //       centerPadding: "40px",
    //       slidesToShow: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       arrows: false,
    //       centerMode: true,
    //       centerPadding: "40px",
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  };
  return (
    <div
      style={{
        maxWidth: "1280px",
        // height: "950px",
        margin: "50px auto",
        // background: "antiquewhite",
      }}
    >
      <Slider {...settings}>
        <div>
          <div className={classes.slickCardHolder}>
            <section
              style={{
                width: "320px",
                opacity: 0.5,
                margin: "auto",
              }}
            >
              <div className={classes.slickCardImage}>
                <img
                  src={OnlinePaymentP12x}
                  style={{
                    height: "250px",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>

              <br />
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Online Payment
              </Typography>
            </section>
          </div>
        </div>
        <div>
          <div className={classes.slickCardHolder}>
            <section
              style={{
                width: "320px",
                opacity: 0.5,
                margin: "auto",
              }}
            >
              <div className={classes.slickCardImage}>
                <img
                  src={ContactlessCheckIn2x}
                  style={{
                    height: "250px",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>

              <br />
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Contactless check-in
              </Typography>
            </section>
          </div>
        </div>
        <div>
          <div className={classes.slickCardHolder}>
            <section
              style={{
                width: "320px",
                opacity: 0.5,
                margin: "auto",
              }}
            >
              <div className={classes.slickCardImage}>
                <img
                  src={ActivityFeed2x}
                  style={{
                    height: "250px",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>

              <br />
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Activity Feed
              </Typography>
            </section>
          </div>
        </div>
        <div>
          <div className={classes.slickCardHolder}>
            <section
              style={{
                width: "320px",
                opacity: 0.5,
                margin: "auto",
              }}
            >
              <div className={classes.slickCardImage}>
                <img
                  src={OnlinePaymentP12x}
                  style={{
                    height: "250px",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>

              <br />
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Online Payment
              </Typography>
            </section>
          </div>
        </div>
        <div>
          <div className={classes.slickCardHolder}>
            <section
              style={{
                width: "320px",
                opacity: 0.5,
                margin: "auto",
              }}
            >
              <div className={classes.slickCardImage}>
                <img
                  src={ContactlessCheckIn2x}
                  style={{
                    height: "250px",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>

              <br />
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Contactless check-in
              </Typography>
            </section>
          </div>
        </div>
        <div>
          <div className={classes.slickCardHolder}>
            <section
              style={{
                width: "320px",
                opacity: 0.5,
                margin: "auto",
              }}
            >
              <div className={classes.slickCardImage}>
                <img
                  src={ActivityFeed2x}
                  style={{
                    height: "250px",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>

              <br />
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Activity Feed
              </Typography>
            </section>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Test;
