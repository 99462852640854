import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../assets/css/menuBar.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Menubar = () => {
  let location = useLocation();
  let pathName = location.pathname; 

  const productActive = () => {
    let isActive = false;
    let checkPathName = [
      "/for-owners-and-directors",
      "/for-teachers",
      "/for-parents",
    ];
    if (checkPathName.includes(pathName)) {
      isActive = true;
    }
    return isActive;
  };
  const featuresActive = () => {
    let isActive = false;
    let checkPathName = [
      "/feature-overviews",
      "/attendance-and-check-ins",
      "/activity-tracking",
      "/center-management",
      "/billing",
      "/managing-learning-path",
      "/parents-engagements",
    ];
    if (checkPathName.includes(pathName)) {
      isActive = true;
    }
    return isActive;
  };
  const resourcesActive = () => {
    let isActive = false;
    let checkPathName = ["/case-studies", "/blog", "/find-nearby-child-care"];
    if (checkPathName.includes(pathName)) {
      isActive = true;
    }
    return isActive;
  };
  return (
    <nav>
      <ul className="nav">
        <li>
          <Link to="#" className={productActive() && "activeMenu"}>
            Product{" "}
            <span>
              <ExpandMoreIcon />
            </span>
          </Link>
          <ul>
            <li>
              <Link
                to="/for-owners-and-directors"
                className={
                  pathName === "/for-owners-and-directors" && "activeMenu"
                }
              >
                For Owners & Directors
              </Link>
            </li>
            <li>
              <Link
                to="/for-teachers"
                className={pathName === "/for-teachers" && "activeMenu"}
              >
                For Teachers
              </Link>
            </li>
            <li>
              <Link
                to="/for-parents"
                className={pathName === "/for-parents" && "activeMenu"}
              >
                For Parents
              </Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="#" className={featuresActive() && "activeMenu"}>
            Features{" "}
            <span>
              <ExpandMoreIcon />
            </span>
          </Link>
          <ul>
            <li>
              <Link
                to="/feature-overviews"
                className={pathName === "/feature-overviews" && "activeMenu"}
              >
                Feature Overviews
              </Link>
            </li>
            <li>
              <Link
                to="/attendance-and-check-ins"
                className={
                  pathName === "/attendance-and-check-ins" && "activeMenu"
                }
              >
                Attendance & check-ins
              </Link>
            </li>
            <li>
              <Link
                to="/activity-tracking"
                className={pathName === "/activity-tracking" && "activeMenu"}
              >
                Activity Tracking
              </Link>
            </li>
            <li>
              <Link
                to="/center-management"
                className={pathName === "/center-management" && "activeMenu"}
              >
                Center Management
              </Link>
            </li>
            <li>
              <Link
                to="/billing"
                className={pathName === "/billing" && "activeMenu"}
              >
                Billing
              </Link>
            </li>
            <li>
              <Link
                to="/managing-learning-path"
                className={
                  pathName === "/managing-learning-path" && "activeMenu"
                }
              >
                Managing Learning Path
              </Link>
            </li>
            <li>
              <Link
                to="/parents-engagements"
                className={pathName === "/parents-engagements" && "activeMenu"}
              >
                Parents Engagements
              </Link>
            </li>
          </ul>
        </li>

        <li>
          <Link
            to="/pricing"
            className={pathName === "/pricing" && "activeMenu"}
          >
            Pricing
          </Link>
        </li>

        <li>
          <Link to="#" className={resourcesActive() && "activeMenu"}>
            Resources{" "}
            <span>
              <ExpandMoreIcon />
            </span>
          </Link>
          <ul>
            {/* <li>
              <Link to="/documentation">Documentation</Link>
            </li> */}
            {/* <li>
              <Link to="/e-books">eBooks</Link>
            </li> */}
            <li>
              <Link
                to="/case-studies"
                className={pathName === "/case-studies" && "activeMenu"}
              >
                Case Studies
              </Link>
            </li>
            <li>
              <Link to="/blog" className={pathName === "/blog" && "activeMenu"}>
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/find-nearby-child-care"
                className={
                  pathName === "/find-nearby-child-care" && "activeMenu"
                }
              >
                Find Nearby Childcare
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Menubar;
