import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ActivityFeed2x from "../assets/images/ActivityFeed2x.png";
import ContactlessCheckIn2x from "../assets/images/ContactlessCheckIn2x.png";
import OnlinePaymentP12x from "../assets/images/OnlinePaymentP12x.png";
import { Grid, IconButton, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  sliderHolder: {
    maxWidth: "980px",
    // height: "950px",
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "850px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "700px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "280px",
    },
  },
  slickCardHolder: {
    height: "500px",
    // border: "1px solid",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      height: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "270px",
    },
  },
  slickCardImage: {
    background: "#fff",
    padding: "30px",
    boxShadow:
      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  sectionStyle: {
    width: "280px",
    opacity: 0.5,
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      width: "200px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "180px",
    },
  },
  ImageStyle: {
    height: "280px",
    display: "block",
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      height: "160px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "120px",
    },
  },
  rightButtonStyle: {
    background: "#fff !important",
    border: "1px solid #ddd",
    position: "relative",
    top: -40,
    [theme.breakpoints.down("xs")]: {
      padding: "7px",
    },
  },
  rightIconStyle: {
    color: "#EE6741",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
    },
  },
  leftButtonStyle: {
    background: "#fff !important",
    border: "1px solid #ddd",
    position: "relative",
    top: -40,
    left: -38,
    [theme.breakpoints.down("xs")]: {
      padding: "7px",
      left: -15,
    },
  },
  leftIconStyle: {
    color: "#EE6741",
    position: "relative",
    left: 5,
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
      left: 3,
    },
  },
  titleStyle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px !important",
    },
  },
}));
const MySlickSlider = () => {
  const classes = useStyles();
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "", cursor: "auto" }}
      >
        <IconButton onClick={onClick} className={classes.rightButtonStyle}>
          <ArrowForwardIosIcon className={classes.rightIconStyle} />
        </IconButton>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "", cursor: "auto" }}
      >
        <IconButton onClick={onClick} className={classes.leftButtonStyle}>
          <ArrowBackIosIcon className={classes.leftIconStyle} />
        </IconButton>
      </div>
    );
  }
  const settings = {
    centerMode: true,
    centerPadding: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    // dots: true,
    // autoplay: true,
    // centerPadding: "60px",
    // autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          //   autoplay: true,
          //   autoplaySpeed: 2000,
          //   dots: true,
        },
      },
    ],
  };
  return (
    <div className={classes.sliderHolder}>
      <Slider {...settings}>
        <div>
          <div className={classes.slickCardHolder}>
            <section className={classes.sectionStyle}>
              <div className={classes.slickCardImage}>
                <img
                  src={OnlinePaymentP12x}
                  alt=""
                  className={classes.ImageStyle}
                />
              </div>

              <br />
              <Typography
                className={classes.titleStyle}
                variant="h5"
                style={{ textAlign: "center" }}
              >
                Online Payment
              </Typography>
            </section>
          </div>
        </div>
        <div>
          <div className={classes.slickCardHolder}>
            <section className={classes.sectionStyle}>
              <div className={classes.slickCardImage}>
                <img
                  src={ContactlessCheckIn2x}
                  alt=""
                  className={classes.ImageStyle}
                />
              </div>

              <br />
              <Typography
                className={classes.titleStyle}
                variant="h5"
                style={{ textAlign: "center" }}
              >
                Contactless check-in
              </Typography>
            </section>
          </div>
        </div>
        <div>
          <div className={classes.slickCardHolder}>
            <section className={classes.sectionStyle}>
              <div className={classes.slickCardImage}>
                <img
                  src={ActivityFeed2x}
                  alt=""
                  className={classes.ImageStyle}
                />
              </div>

              <br />
              <Typography
                className={classes.titleStyle}
                variant="h5"
                style={{ textAlign: "center" }}
              >
                Activity Feed
              </Typography>
            </section>
          </div>
        </div>
        <div>
          <div className={classes.slickCardHolder}>
            <section className={classes.sectionStyle}>
              <div className={classes.slickCardImage}>
                <img
                  src={OnlinePaymentP12x}
                  alt=""
                  className={classes.ImageStyle}
                />
              </div>

              <br />
              <Typography
                className={classes.titleStyle}
                variant="h5"
                style={{ textAlign: "center" }}
              >
                Online Payment
              </Typography>
            </section>
          </div>
        </div>
        <div>
          <div className={classes.slickCardHolder}>
            <section className={classes.sectionStyle}>
              <div className={classes.slickCardImage}>
                <img
                  src={ContactlessCheckIn2x}
                  alt=""
                  className={classes.ImageStyle}
                />
              </div>

              <br />
              <Typography
                className={classes.titleStyle}
                variant="h5"
                style={{ textAlign: "center" }}
              >
                Contactless check-in
              </Typography>
            </section>
          </div>
        </div>
        <div>
          <div className={classes.slickCardHolder}>
            <section className={classes.sectionStyle}>
              <div className={classes.slickCardImage}>
                <img
                  src={ActivityFeed2x}
                  alt=""
                  className={classes.ImageStyle}
                />
              </div>

              <br />
              <Typography
                className={classes.titleStyle}
                variant="h5"
                style={{ textAlign: "center" }}
              >
                Activity Feed
              </Typography>
            </section>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default MySlickSlider;
