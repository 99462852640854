import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import PerfectAssistant from "../../assets/images/PerfectAssistant.png";

import TeacherVideo from "../../assets/images/children_with_nurse_playroom.png";

import PurpleCircle from "../../assets/images/PurpleCircle.png";
import LessPaperWorkFocusOnKids from "../../assets/images/LessPaperWorkFocusOnKids.png";
import owner_youtube from "../../assets/images/owner_youtube.png";
import PlayIcon from "../../assets/images/PlayIcon.png";
import children_with_nurse_playroom2 from "../../assets/images/children_with_nurse_playroom2.png";
import Messaging from "../../assets/images/messaging.svg";
import NoMoreChasingKids from "../../assets/images/no_more_chasing_kids.svg";
import RealTimeUpdates from "../../assets/images/real_time_updates.svg";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "0px 30px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },

  banner: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      marginTop: 10,
    },
  },

  banner_image: {
    flex: 1,
    padding: 30,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  banner_content: {
    flex: 1,
    padding: 30,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: 20,
    },
  },
  banner_button: {
    padding: "13px 35px  !important",

    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      padding: "7px 16px  !important",
    },
  },

  banner_content_text: {
    fontSize: 24,
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  banner_headline: {
    fontWeight: 600,
    marginBottom: 80,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 20,
      fontSize: "2rem",
    },
  },
  margin_left_20: {
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 3,
      marginBottom: 10,
    },
  },
  // wrapper2: {
  //   marginTop: 120,
  //   padding: "120px 120px 60px 120px",
  //   width: "100%",
  //   background: "#E3EBFC",
  //   position: "relative",
  //   [theme.breakpoints.down("xs")]: {
  //     padding: 0,
  //     marginTop: 10,
  //   },
  // },
  wrapper2: {
    marginTop: 120,
    padding: "120px 120px 60px 120px",
    width: "100%",
    background: "#E3EBFC",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "90px 120px 60px 120px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "70px  0 0 0",
      marginTop: 50,
    },
  },
  // card: {
  //   position: "absolute",
  //   zIndex: 9,
  //   display: "flex",
  //   width: "700px",
  //   left: "calc(50% - 350px)",
  //   top: -80,
  //   [theme.breakpoints.down("sm")]: {
  //     width: "560px",
  //     left: "calc(50% - 280px)",
  //   },
  //   [theme.breakpoints.down("xs")]: {
  //     display: "none",
  //   },
  // },
  card: {
    position: "absolute",
    // zIndex: 9,
    display: "flex",
    width: "700px",
    left: "calc(50% - 350px)",
    top: -80,
    [theme.breakpoints.down("sm")]: {
      width: "560px",
      left: "calc(50% - 280px)",
    },
    [theme.breakpoints.down("xs")]: {
      // display: "none",
      width: "310px",
      left: "calc(50% - 155px)",
      top: -38,
    },
  },
  right: {
    flex: 1,
    boxSizing: "border-box",
    padding: "20px 25px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
  left: {
    // backgroundImage: `url(${DirectorDemoVideo})`,
    // backgroundSize: "cover",
    width: 300,
    "& img": {
      width: "100%",
      height: "100%",
    },
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: 240,
    },
    [theme.breakpoints.down("xs")]: {
      width: 120,
    },
  },
  right_text: {
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  play: {
    color: "#0B4295",
    padding: "13px 0px  !important",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      padding: "3px 0px  !important",
      fontSize: "12px !important",
      "& svg": {
        fontSize: "16px !important",
      },
    },
  },
  // left: {
  //   backgroundImage: `url(${TeacherVideo})`,
  //   backgroundSize: "cover",
  //   width: 300,
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   [theme.breakpoints.down("sm")]: {
  //     width: 240,
  //   },
  // },
  // right: {
  //   padding: "20px 25px",
  //   [theme.breakpoints.down("sm")]: {
  //     fontSize: 16,
  //   },
  // },
  // right_text: {
  //   fontSize: 20,
  //   [theme.breakpoints.down("sm")]: {
  //     fontSize: 16,
  //   },
  // },
  // play: {
  //   color: "#0B4295",
  //   padding: "13px 0px  !important",
  //   [theme.breakpoints.down("xs")]: {
  //     marginLeft: 0,
  //   },
  // },
  activity_box: {
    textAlign: "center",
    padding: "15px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15,
    },
  },

  activity_image: {
    maxWidth: "100%",
    // background: `url(${PurpleCircle})`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    padding: 20,
    marginBottom: 15,
    [theme.breakpoints.down("sm")]: {
      // backgroundSize: "70px",
      width: "110px",
      marginBottom: 0,
    },
  },

  activity_headline: {
    padding: 10,
  },
  activity_wrapper: {
    padding: "60px 120px 0px 120px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px 0px",
    },
  },
  activity_wrapper2: {
    padding: "40px 120px 0px 120px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px 0px",
      maxWidth: "90%",
      margin: "auto",
    },
  },
  activity_wrapper3: {
    padding: "100px 120px 100px 120px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px 0px",
    },
  },
  h4: {
    marginBottom: "40px",
  },
  h6: {
    marginTop: 0,
  },
  align_left: {
    textAlign: "left",
  },
  margin_bottom_30: {
    marginBottom: 30,
  },
  image: {
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      display: "block",
      margin: "auto",
    },
  },
  image2: {
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "220px",
      margin: "auto",
      display: "block",
    },
  },
  parent_child: {
    display: "flex",
    justifyContent: "center",
  },
  // tag: {
  //   textAlign: "center",
  //   background: "#E3EBFC",
  //   borderRadius: 13,
  //   padding: "40px 20px",
  //   margin: 15,
  //   "& h3": {
  //     fontWeight: 600,
  //     marginBottom: 10,
  //     [theme.breakpoints.down("sm")]: {
  //       margin: "0px 60px",
  //     },
  //   },
  // },

  // tag_text: {
  //   marginTop: -10,
  // },
  tag: {
    textAlign: "center",
    background: "#E3EBFC",
    borderRadius: 13,
    padding: "40px 20px",
    margin: 15,
    "& h3": {
      fontWeight: 600,
      marginBottom: 10,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 60px",
    },
  },
   
  tag_text: {
    marginTop: -10,
  },
  center: {
    textAlign: "center",
  },
  manage_center_image_div: {
    width: "90%",
    margin: "auto",
  },
}));

const Teachers = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        <Grid container className={classes.wrapper}>
          <Grid item lg={12} md={12}>
            <div className={classes.banner}>
              <div className={classes.banner_content}>
                <Typography variant="h3" className={classes.banner_headline}>
                  Perfect assistant
                </Typography>
                <Typography className={classes.banner_content_text}>
                  Making the lives of teachers easier. Access a digital
                  dashboard to maintain your teaching needs and monitor kids
                  effectively.
                </Typography>
                <div>
                  <Link to="/request-call">
                    <Button
                      variant="contained"
                      color="primary"
                      className={`${classes.banner_button} button`}
                    >
                      Contact Sales
                    </Button>
                  </Link>
                  <Link to="/try-it-free">
                    <Button
                      variant="outlined"
                      color="primary"
                      className={`${classes.banner_button} ${classes.margin_left_20} button`}
                    >
                      Start free trial
                    </Button>
                  </Link>
                </div>
              </div>
              <div className={classes.banner_image}>
                <img src={PerfectAssistant} alt="" />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg">
        <Grid container className={classes.wrapper2}>
          <Card className={classes.card}>
            <div className={classes.left}>
              <img src={children_with_nurse_playroom2} alt="" />
            </div>
            <div className={classes.right}>
              <Typography className={classes.right_text}>
                See what directors are saying about KinderCubby
              </Typography>
              <Button
                // variant="contained"
                color="secondary"
                className={`${classes.play} button`}
                startIcon={<PlayCircleOutlineIcon />}
              >
                Watch In Action
              </Button>
            </div>
          </Card>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.activity_headline}>
              Managing data made easy
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} className={classes.activity_box}>
            <img
              className={classes.activity_image}
              src={RealTimeUpdates}
              alt=""
            />
            <div>
              <Typography variant="h5">Real-time updates</Typography>
              <Typography variant="subtitle1">
                Understand how kids are performing in real-time. Get true
                insights into their performance, location, and schedule with as
                little as one notification.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={4} md={4} className={classes.activity_box}>
            <img
              className={classes.activity_image}
              src={NoMoreChasingKids}
              alt=""
            />
            <div>
              <Typography variant="h5">No more chasing kids</Typography>
              <Typography variant="subtitle1">
                Know where all of your kids are located with a touch of the
                button, so there is no need to scramble looking for them
                anymore.
              </Typography>
            </div>
          </Grid>
          <Grid item lg={4} md={4} className={classes.activity_box}>
            <img className={classes.activity_image} src={Messaging} alt="" />
            <div>
              <Typography variant="h5">Messaging</Typography>
              <Typography variant="subtitle1">
                Message your manager or a parent directly with our system. Keep
                all key members updated about a child’s status.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg">
        <Grid container spacing={3} className={`${classes.activity_wrapper}`}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h4">
              Automate more, less paperwork <br /> more focus on children
            </Typography>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.parent_child}
          >
            <div className={classes.manage_center_image_div}>
              <img
                src={LessPaperWorkFocusOnKids}
                alt=""
                className={classes.image2}
              />
            </div>
          </Grid>
          <Grid item lg={6} md={6}>
            <Typography variant="h5" className={classes.align_left}>
              Automate attendance sheet
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.margin_bottom_30}
            >
              Record attendance digitally with automatic check-ins and through
              our electronic record system.
            </Typography>

            <Typography variant="h5" className={classes.align_left}>
              Lesson Planning
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.margin_bottom_30}
            >
              Create detailed lesson plans and assignments for any topic.
            </Typography>

            <Typography variant="h5" className={classes.align_left}>
              Scheduling
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.margin_bottom_30}
            >
              Schedule lesson plans throughout the week, 1:1’s with parents, and
              even organize your personal work schedule to become an effective
              teacher.
            </Typography>

            <Typography variant="h5" className={classes.align_left}>
              Distance Learning
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.margin_bottom_30}
            >
              Deliver lesson plans, homework assignments, and other learning
              material to children remotely. Assign, complete, and submit
              assignments digitally on our platform.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg">
        <div className={`${classes.activity_wrapper2}`}>
          <Grid container justify="center" alignItems="center" spacing={5}>
            <Grid item lg={12} md={12}>
              <Typography variant="h4" className={classes.h4}>
                Impact of KinderCubby on teachers
              </Typography>
              <img src={owner_youtube} alt="" className={classes.image} />
            </Grid>

            {/* <Grid item lg={4} md={4}>
              <div className={classes.tag}>
                <Typography variant="h3">85%</Typography>
                <Typography variant="h5">of users</Typography>
                <Typography variant="body1" className={classes.tag_text}>
                  Would recommend KinderCubby
                </Typography>
              </div>
            </Grid>

            <Grid item lg={4} md={4}>
              <div className={classes.tag}>
                <Typography variant="h3">100%</Typography>
                <Typography variant="h5">of users</Typography>
                <Typography variant="body1" className={classes.tag_text}>
                  Are satisfied with the experience
                </Typography>
              </div>
            </Grid>
            <Grid item lg={4} md={4}>
              <div className={`${classes.tag} `}>
                <Typography variant="h3">10+</Typography>
                <Typography variant="h5">of users</Typography>
                <Typography variant="body1" className={classes.tag_text}>
                  Kid moments have been recorded
                </Typography>
              </div>
            </Grid> */}

<Grid item lg={4} md={4} sm={12} xs={12}>
              <div className={classes.tag}>
                <Typography variant="h3">85%</Typography>
                <Typography variant="h5">of users</Typography>
                <Typography variant="body1" className={classes.tag_text}>
                  Would recommend KinderCubby
                </Typography>
              </div>
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <div className={classes.tag}>
                <Typography variant="h3">100%</Typography>
                <Typography variant="h5">of users</Typography>
                <Typography variant="body1" className={classes.tag_text}>
                  Are satisfied with the experience
                </Typography>
              </div>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <div className={`${classes.tag} `}>
                <Typography variant="h3">10+</Typography>
                <Typography variant="h5">of users</Typography>
                <Typography variant="body1" className={classes.tag_text}>
                  Kid moments have been recorded
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>

      <Container maxWidth="lg">
        <Grid container className={`${classes.activity_wrapper3}`}>
          <Grid item lg={12} md={12}>
            <Typography variant="h4">Invite your school</Typography>
            <Typography variant="h6" className={classes.h6}>
              Get connected with our KinderCubby team today and setup your
              established or new childcare center with our system. Watch as we
              transform your daycare into a true digital experience fit for
              everyone.
            </Typography>
            <div className={classes.center}>
              <Link to="/try-it-free">
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.banner_button} button`}
                >
                  Try it Free
                </Button>
              </Link>
              <Link to="pricing">
                <Button
                  variant="outlined"
                  color="primary"
                  className={`${classes.banner_button} ${classes.margin_left_20} button`}
                >
                  Explore Pricing
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Teachers;
