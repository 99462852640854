import React, { useEffect } from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  h4: {
    // textAlign: "left",
    paddingBottom: 0,
  },

  block: {
    marginBottom: "50px",
  },

  userCardTitle: {
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      // minHeight: "60px",
      fontSize: "0.875rem",
    },
    // [theme.breakpoints.down("sm")]: {
    //   // minHeight: "60px",
    //   fontSize: "1rem",
    // },
  },

  subtitle1: {
    fontSize: "15px !important",
    [theme.breakpoints.down("md")]: {
      // minHeight: "60px",
      fontSize: "12px !important",
    },
  },

  listStyle: {
    listStyleType: "circle",
    marginBottom: "7px",
  },
  listStyle2: {
    listStyleType: "square",
    marginBottom: "7px",
  },
  listStyle3: {
    listStyleType: "upper-roman",
    marginBottom: "7px",
  },
  listStyle4: {
    listStyleType: "circle",
    marginBottom: "30px",
  },
  marginStyle: {
    marginBottom: "15px",
  },
  titleBox: {
    background: "#E3EBFC",
    padding: "60px 0 120px",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      padding: "30px 0 60px",
    },
  },
  box: {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    padding: "70px",
    width: "80%",
    margin: "auto",
    position: "relative",
    top: "-60px",
    background: "#fff",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      top: "-30px",
      padding: "20px",
    },
  },
  ulStyle: {
    marginLeft: "70px",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "40px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
    },
  },
  nonListStyle: {
    listStyleType: "none",
    marginLeft: "-70px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "-40px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "-0px",
    },
  },
  titleMargin: {
    marginLeft: "52px",
    marginTop: "30px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "32px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "12px",
    },
  },
  olStyle: {
    marginLeft: "140px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "80px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "35px",
    },
  },
}));
const TermsAndConditions = () => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container maxWidth="lg">
      <div className={classes.titleBox}>
        <Typography variant="h4" className={classes.h4}>
          Terms and Conditions
        </Typography>
      </div>
      <div className={classes.box}>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            OVERVIEW
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            This website{" "}
            <a
              href="https://www.kindercubby.com"
              target="_blank"
              style={{ color: "blue" }}
            >
              {" "}
              www.kindercubby.com
            </a>{" "}
            and mobile app “Kinder Cubby” is operated by FinioTech and
            KinderCubby (“Kinder Cubby”, “app”, “website”, “we”, “our” or
            “us’”). Kinder Cubby offers this website, including all information,
            tools, services and mobile app available from this site to you, the
            user, conditioned upon your acceptance of all terms, conditions,
            policies and notices stated here. By using our app, site and/ or
            subscribing, purchasing something from us, you engage in our
            “Service” and agree to be bound by the following terms and
            conditions (“Terms of Service”, “Terms”), including those additional
            terms and conditions and policies referenced herein and/or available
            by hyperlink. These Terms of Service apply to all users of the site,
            app including without limitation users who are browsers, vendors,
            customers, subscriber, merchants, and/ or contributors of content.
            Please read these Terms of Service carefully before accessing or
            using our website and/or app. By accessing or using any part of the
            site, you agree to be bound by these Terms of Service. If you do not
            agree to all the terms and conditions of this agreement, then you
            must not access the website nor use any services. If these Terms of
            Service are considered an offer, acceptance is expressly limited to
            these Terms of Service. Any new features or tools which are added to
            the current website shall also be subject to the Terms of Service.
            You can review the most current version of the Terms of Service at
            any time on this page. We reserve the right to update, change or
            replace any part of these Terms of Service by posting updates and/or
            changes to our website. You are expected to check this page
            periodically for changes. Your continued use of or access to the
            website following the posting of any changes constitutes acceptance
            of those changes.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            USER ACCOUNT REGESTRATION
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            To avail our services, you must register and create a User Account
            on Kinder Cubby. It is a condition that all of the information you
            provide on the Website is correct, complete and current. You may
            need to make payment and/or subscribe if you want to use our
            Standard and/or Premium packages rather than the Free one. The
            amount of the paid packages may change time to time with or without
            notifying to you. Kinder Cubby reserves the right, at any time, to
            verify the information which you provide in account registration.
            Kinder Cubby reserves the right to suspend your account if unable to
            verify. You are fully responsible for any and all activities
            including your confidentiality on login details. You are expected
            not to disclose these details to any person or entity. If you have a
            reason to believe that someone has used or is using your account
            without your permission or if your account has been subject to any
            other breach of security immediately notify Kinder Cubby at
            hello@kindercubby.com. By Violating any terms Kinder Cubby reserves
            the right to disable any registered User Account.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            MODIFICATIONS TO THE SERVICE AND PRICES AND FEES
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            Prices for our products, services and/or subscription fees are
            subject to change without notice. We reserve the right at any time
            to modify or discontinue the Service (or any part or content
            thereof) without notice at any time. We shall not be liable to you
            or to any third-party for any modification, price change, fee,
            suspension or discontinuance of the Service.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            PERSONAL INFORMATION OR YOUR PRIVACY
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            Your submission of personal information through the store is
            governed by our Privacy Policy.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            PROHIBITED USES
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            In addition to other prohibitions as set forth in the Terms of
            Service, you are prohibited from using the site, app or its content:
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            (a) for any unlawful purpose;
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            (b) to solicit others to perform or participate in any unlawful
            acts;
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            (c) to violate any international, federal, provincial or state
            regulations, rules, laws, or local ordinances;
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            (d) to infringe upon or violate our intellectual property rights or
            the intellectual property rights of others;
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            (e) to harass, abuse, insult, harm, defame, slander, disparage,
            intimidate, or discriminate based on gender, sexual orientation,
            religion, ethnicity, race, age, national origin, or disability;
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            (f) to submit false or misleading information;
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            (g) to upload or transmit viruses or any other type of malicious
            code that will or may be used in any way that will affect the
            functionality or operation of the Service or of any related website,
            other websites, or the Internet;
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            (h) to collect or track the personal information of others;
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            (i) to spam, phish, pharm, pretext, spider, crawl, or scrape;
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            (j) for any obscene or immoral purpose; or
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            (k) to interfere with or circumvent the security features of the
            Service or any related website, other websites, or the Internet.
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            We reserve the right to terminate your use of the Service or any
            related website for violating any of the prohibited uses.
          </Typography>
        </div>

        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            If, at our request, you send certain specific submissions (for
            example contest entries) or without a request from us you send
            creative ideas, suggestions, proposals, plans, or other materials,
            whether online, by email, by postal mail, or otherwise
            (collectively, 'comments’), you agree that we may, at any time,
            without restriction, edit, copy, publish, distribute, translate and
            otherwise use in any medium any comments that you forward to us. We
            are and shall be under no obligation (1) to maintain any comments in
            confidence; (2) to pay compensation for any comments; or (3) to
            respond to any comments. We may, but have no obligation to, monitor,
            edit or remove content that we determine in our sole discretion are
            unlawful, offensive, threatening, libelous, defamatory,
            pornographic, obscene or otherwise objectionable or violates any
            party’s intellectual property or these Terms of Service. You agree
            that your comments will not violate any right of any third-party,
            including copyright, trademark, privacy, personality or other
            personal or proprietary right. You further agree that your comments
            will not contain libelous or otherwise unlawful, abusive or obscene
            material, or contain any computer virus or other malware that could
            in any way affect the operation of the Service or any related
            website. You may not use a false e-mail address, pretend to be
            someone other than yourself, or otherwise mislead us or
            third-parties as to the origin of any comments. You are solely
            responsible for any comments you make and their accuracy. We take no
            responsibility and assume no liability for any comments posted by
            you or any third-party.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            COPYRIGHT
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            This website and each and everything belong to the Kinder Cubby.
            Nevertheless your content and information such as all of the data
            including photos and videos that is being uploaded to the child’s
            account belongs to the child’s parents and the school that created
            the content. Our role is to preserve, store and maintain the data
            with highest level of security. Parents and school can control how
            the data is being shared and seen by whom. Kinder Cubby has the
            rights to store child’s medical records such as allergies and others
            by maintaining HIPAA. School and child’s parents are responsible for
            all the data shared by them. We take all the reasonable security
            available by technologies to prevent unauthorized access. If you
            still find yourself breach of any data that belongs to you; Please
            contact us and we will take all reasonable action.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            We do not guarantee, represent or warrant that your use of our
            service will be uninterrupted, timely, secure or error-free. We do
            not warrant that the results that may be obtained from the use of
            the service will be accurate or reliable. You agree that from time
            to time we may remove the service for indefinite periods of time or
            cancel the service at any time, without notice to you. You expressly
            agree that your use of, or inability to use, the service is at your
            sole risk. The service and all products and services delivered to
            you through the service are (except as expressly stated by us)
            provided ‘as is' and ‘as available' for your use, without any
            representation, warranties or conditions of any kind, either express
            or implied, including all implied warranties or conditions of
            merchantability, merchantable quality, fitness for a particular
            purpose, durability, title, and non-infringement. In no case shall
            Kinder Cubby, our directors, officers, employees, affiliates,
            agents, contractors, interns, suppliers, service providers or
            licensors be liable for any injury, loss, claim, or any direct,
            indirect, incidental, punitive, special, or consequential damages of
            any kind, including, without limitation lost profits, lost revenue,
            lost savings, loss of data, replacement costs, or any similar
            damages, whether based in contract, tort (including negligence),
            strict liability or otherwise, arising from your use of any of the
            service or any products procured using the service, or for any other
            claim related in any way to your use of the service or any product,
            including, but not limited to, any errors or omissions in any
            content, or any loss or damage of any kind incurred as a result of
            the use of the service or any content (or product) posted,
            transmitted, or otherwise made available via the service, even if
            advised of their possibility. Because some states or jurisdictions
            do not allow the exclusion or the limitation of liability for
            consequential or incidental damages, in such states or
            jurisdictions, our liability shall be limited to the maximum extent
            permitted by law.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            INDEMNIFICATION
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            You agree to indemnify, defend and hold harmless Kinder Cubby and
            our parent, subsidiaries, affiliates, partners, officers, directors,
            agents, contractors, licensors, service providers, subcontractors,
            suppliers, interns and employees, harmless from any claim or demand,
            including reasonable attorneys’ fees, made by any third-party due to
            or arising out of your breach of these Terms of Service or the
            documents they incorporate by reference, or your violation of any
            law or the rights of a third-party.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            SEVERABILITY
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            In the event that any provision of these Terms of Service is
            determined to be unlawful, void or unenforceable, such provision
            shall nonetheless be enforceable to the fullest extent permitted by
            applicable law, and the unenforceable portion shall be deemed to be
            severed from these Terms of Service, such determination shall not
            affect the validity and enforceability of any other remaining
            provisions.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            TERMINATION
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            The obligations and liabilities of the parties incurred prior to the
            termination date shall survive the termination of this agreement for
            all purposes. These Terms of Service are effective unless and until
            terminated by either you or us. You may terminate these Terms of
            Service at any time by notifying us that you no longer wish to use
            our Services, or when you cease using our site. If in our sole
            judgment you fail, or we suspect that you have failed, to comply
            with any term or provision of these Terms of Service, we also may
            terminate this agreement at any time without notice and you will
            remain liable for all amounts due up to and including the date of
            termination; and/or accordingly may deny you access to our Services
            (or any part thereof).
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            ENTIRE AGREEMENT
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            The failure of us to exercise or enforce any right or provision of
            these Terms of Service shall not constitute a waiver of such right
            or provision. These Terms of Service and any policies or operating
            rules posted by us on this site or in respect to The Service
            constitutes the entire agreement and understanding between you and
            us and govern your use of the Service, superseding any prior or
            contemporaneous agreements, communications and proposals, whether
            oral or written, between you and us (including, but not limited to,
            any prior versions of the Terms of Service). Any ambiguities in the
            interpretation of these Terms of Service shall not be construed
            against the drafting party.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            GOVERNING LAW
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            These Terms of Service and any separate agreements whereby we
            provide you Services shall be governed by and construed in
            accordance with the laws of Virginia, United States of America.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            CHANGES TO TERMS OF SERVICE
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            You can review the most current version of the Terms of Service at
            any time at this page. We reserve the right, at our sole discretion,
            to update, change or replace any part of these Terms of Service by
            posting updates and changes to our website. It is your
            responsibility to check our website periodically for changes. Your
            continued use of or access to our website or the Service following
            the posting of any changes to these Terms of Service constitutes
            acceptance of those changes.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            CONTACT INFORMATION
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            Questions about the Terms of Service should be sent to us at
            <a
              href="https://hello@kindercubby.com"
              target="_blank"
              style={{ color: "blue" }}
            >
              {" "}
              hello@kindercubby.com
            </a>{" "}
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            EFFECTIVE DATE: 01/20/2022
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default TermsAndConditions;
