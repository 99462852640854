import React from 'react'

const Blog = () => {
  return (
    <div>
      
    </div>
  )
}

export default Blog
