import React, { useEffect } from "react";
import { Container, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  h4: {
    // textAlign: "left",
    paddingBottom: 0,
  },

  block: {
    marginBottom: "50px",
  },

  userCardTitle: {
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      // minHeight: "60px",
      fontSize: "0.875rem",
    },
    // [theme.breakpoints.down("sm")]: {
    //   // minHeight: "60px",
    //   fontSize: "1rem",
    // },
  },

  subtitle1: {
    fontSize: "15px !important",
    [theme.breakpoints.down("md")]: {
      // minHeight: "60px",
      fontSize: "12px !important",
    },
  },

  listStyle: {
    listStyleType: "circle",
    marginBottom: "7px",
  },
  listStyle2: {
    listStyleType: "square",
    marginBottom: "7px",
  },
  listStyle3: {
    listStyleType: "upper-roman",
    marginBottom: "7px",
  },
  listStyle4: {
    listStyleType: "circle",
    marginBottom: "30px",
  },
  marginStyle: {
    marginBottom: "15px",
  },
  titleBox: {
    background: "#E3EBFC",
    padding: "60px 0 120px",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      padding: "30px 0 60px",
    },
  },
  box: {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    padding: "70px",
    width: "80%",
    margin: "auto",
    position: "relative",
    top: "-60px",
    background: "#fff",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      top: "-30px",
      padding: "20px",
    },
  },
  ulStyle: {
    marginLeft: "70px",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "40px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
    },
  },
  nonListStyle: {
    listStyleType: "none",
    marginLeft: "-70px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "-40px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "-0px",
    },
  },
  titleMargin: {
    marginLeft: "52px",
    marginTop: "30px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "32px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "12px",
    },
  },
  olStyle: {
    marginLeft: "140px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "80px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "35px",
    },
  },
}));
const PrivacyPolicy = () => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container maxWidth="lg">
      <div className={classes.titleBox}>
        <Typography variant="h4" className={classes.h4}>
          Privacy Policy
        </Typography>
      </div>
      <div className={classes.box}>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Definition
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            In these Terms and Conditions following terms have the following
            meanings:
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            “Website” means the website at{" "}
            <a
              href="https://www.kindercubby.com"
              target="_blank"
              style={{ color: "blue" }}
            >
              {" "}
              www.kindercubby.com
            </a>
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.subtitle1} ${classes.marginStyle}`}
          >
            “Kinder Cubby”, “FinioTech”, “we”, “our” or “us” means Kinder Cubby.
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            “User Account” means the account which you created with your
            verified email to use on the Website.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Overview
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            Kinder Cubby is a day care platform where you need to open a User
            Account to get the services. By opening a User Account, we get some
            information including personal information from you. We do respect
            and protect your information under the applicable law.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Where We Store Your Information
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            By submitting your personal data, you give consent to transfer,
            process, and store your personal data outside of your residence. We
            shall take reasonable steps to ensure your data to be protected
            securely in accordance with this privacy policy.
          </Typography>
        </div>

        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Information We Collect
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            We collect information when you register as a user of our website:
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            <ul className={classes.ulStyle}>
              <li className={classes.listStyle}>
                <b>Description:</b> Your current picture/profile
                picture(optional), user name, password.
              </li>
              <li className={classes.listStyle}>
                <b>Contact:</b> Verified email address
              </li>
              <li className={classes.listStyle}>
                <b>Your Phone Number:</b> We will use your phone number to
                verify your account so that we can prevent fraud.
              </li>
              <li className={classes.listStyle}>
                <b> Your Payment Information:</b> If you choose a direct payment
                gateway to complete your purchase, Stripe stores your credit
                card data. It is coded through the Payment Card Industry Data
                Security Standard (PCI-DSS). Your purchase transaction data is
                stored only as long as is necessary to complete your purchase
                transaction. After that is complete, your purchase transaction
                information is then deleted. Therefore, we do not store your
                payment information.
              </li>
              <li className={classes.listStyle}>
                <b>Other Information:</b> Photos, Videos etc.
              </li>
            </ul>
          </Typography>
        </div>

        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Visible Information
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            Some information shall be shown publicly in your profile; e.g., your
            profile picture, your username, and other information.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Log Files
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            Kinder Cubby collects the following information when you use the
            website:
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            <ul className={classes.ulStyle}>
              <li className={classes.listStyle2}>
                internet protocol (IP) addresses;
              </li>
              <li className={classes.listStyle2}>browser type;</li>
              <li className={classes.listStyle2}>
                Internet Service Provider (ISP);
              </li>
              <li className={classes.listStyle2}>date and time stamp;</li>
              <li className={classes.listStyle2}>referring/exit pages;</li>
              <li className={classes.listStyle2}>
                {" "}
                For your information, these are not linked to any information
                that is personally identifiable.{" "}
              </li>
            </ul>
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Payment information Security/Shield
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            A direct payment gateway to complete your purchase, Stripe stores
            your credit card data. It is coded through the Payment Card Industry
            Data Security Standard (PCI-DSS). Your purchase transaction data is
            stored only as long as is necessary to complete your purchase
            transaction. After that is complete, your purchase transaction
            information is then deleted.
          </Typography>
        </div>

        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            How We Use Your Information
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            We use the information we collect in various ways; our objective is
            to give you good service without any kind of hassle and legally
            binding. Using information includes:
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            <ul className={classes.ulStyle}>
              <li className={classes.listStyle}>Provide support to you</li>
              <li className={classes.listStyle}>
                Verify your identity to prevent fraud
              </li>
              <li className={classes.listStyle}>
                Provide, operate, and maintain our website
              </li>
              <li className={classes.listStyle}>
                Improve, personalize, and expand our website
              </li>
              <li className={classes.listStyle}>
                Understand and analyze how you use our website
              </li>
              <li className={classes.listStyle}>
                Develop and protect new products, services, features, and
                functionality
              </li>
              <li className={classes.listStyle}>
                Promote any of our services, promotion to you which you may have
                the interest, it will be notified to you after agreeing on the
                opt-out option
              </li>
              <li className={classes.listStyle}>
                Send you communications by email. We may use email to share
                information about our site or to respond to support inquiries.
              </li>
              <li className={`${classes.listStyle} ${classes.nonListStyle}`}>
                Legal Binding :
              </li>
              <li className={classes.listStyle}>
                Necessary to use for any legal purpose or inquire.
              </li>
            </ul>
          </Typography>
        </div>

        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Sharing and Disclosure of Your Information
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            To us information your information is very important. We do not
            share any of your information to others except
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            <ul className={classes.ulStyle}>
              <li className={classes.listStyle}>
                {" "}
                Legal Binding: We may collect, use, retain, and share your
                information if We have a good faith belief that it is reasonably
                necessary to:
              </li>
            </ul>
            <ol className={classes.olStyle}>
              <li className={classes.listStyle3}>
                respond to legal process or to government requests;{" "}
              </li>
              <li className={classes.listStyle3}>
                enforce our agreements, terms and policies;
              </li>
              <li className={classes.listStyle3}>
                prevent, investigate, and address fraud and other illegal
                activity, security, or technical issues;
              </li>
              <li className={classes.listStyle3}>
                protect the rights, property, and safety of our users, or
                others.
              </li>
            </ol>
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Your Rights Regarding Personal Information of Yours
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            In Kinder Cubby your Personal Information/data shall be protected by
            <b>
              CCPA (California Consumer Privacy Act) Privacy Rights
            </b> and <b>GDPR Data Protection Rights</b> as long as we store your
            personal information/data.
          </Typography>
          <Typography
            variant="h5"
            className={`${classes.userCardTitle} ${classes.titleMargin}`}
          >
            CCPA (California Consumer Privacy Act) Privacy Rights:
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            Under the CCPA, among other rights, California consumers have the
            right to:
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            <ul className={classes.ulStyle}>
              <li className={classes.listStyle4}>
                {" "}
                Request that a business that collects a consumer's personal data
                disclose the categories and specific pieces of personal data
                that a business has collected about consumers.
              </li>
              <li className={classes.listStyle4}>
                Request that a business delete any personal data about the
                consumer that a business has collected.
              </li>
              <li className={classes.listStyle4}>
                Request that a business that sells a consumer's personal data,
                not sell the consumer's personal data.
              </li>
            </ul>
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </Typography>
          <Typography
            variant="h5"
            className={`${classes.userCardTitle} ${classes.titleMargin}`}
          >
            GDPR Data Protection Rights:
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            If you are a resident of EEA, the following rights are entitled to
            you:
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            <ul className={classes.ulStyle}>
              <li className={classes.listStyle4}>
                {" "}
                The right to access – You have the right to request copies of
                your personal data. We may charge you a small fee for this
                service.
              </li>
              <li className={classes.listStyle4}>
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate. You also
                have the right to request that we complete the information you
                believe is incomplete.
              </li>
              <li className={classes.listStyle4}>
                The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions.
              </li>
              <li className={classes.listStyle4}>
                The right to restrict processing – You have the right to request
                that we restrict the processing of your personal data, under
                certain conditions.
              </li>
              <li className={classes.listStyle4}>
                The right to object to processing – You have the right to object
                to our processing of your personal data, under certain
                conditions.
              </li>
              <li className={classes.listStyle4}>
                The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </li>
            </ul>
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </Typography>
        </div>

        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Personal Information Sharing with Third Parties
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            We do not share/sell/rent any of your personal information to any
            third party except the Banking/Payment information of yours.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Children's Information
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Data Retention
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            When you place an order, we will maintain your Order Information for
            our records unless and until you ask us to delete this information.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Changes
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            You are expected to recheck our privacy policy frequently. We may
            update this privacy policy from time to time in order to reflect.
            For example, changes to our practices or for other legal or
            regulatory reasons. We reserve the right to modify this privacy
            policy at any time. Changes will take effect immediately upon their
            posting on the website. If we make material changes to this policy,
            we will notify you here that it has been updated, so that you are
            aware of what information we collect, how we use it, and under what
            circumstances.
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            Contact Us
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle1}>
            For more information about our privacy policy, if you have
            questions, please contact us via email at hello@kindercubby.com
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography variant="h5" className={classes.userCardTitle}>
            EFFECTIVE DATE: 01/20/2022
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
