import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import "./App.css";
import TopBar from "./components/TopBar";
import Slide from "@material-ui/core/Slide";
import { SnackbarProvider } from "notistack";
import axios from "axios";
const theme = createMuiTheme({
  typography: {
    allVariants: {
      color: "#464141",
    },
    // fontFamily: ["Fira Sans Condensed", "sans-serif"].join(","),
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    h4: {
      fontSize: "2rem",
      fontWeight: 600,
      padding: "0 0 20px 0",
      textAlign: "center",
      lineHeight: 1.5,
      "@media (max-width:1280px)": {
        fontSize: "1.75rem",
      },
      "@media (max-width:1280px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width:600px)": {
        fontSize: "1.2rem",
      },
    },
    h5: {
      textAlign: "center",
      fontSize: "1.4rem",
      fontWeight: 600,
      marginBottom: 15,
      "@media (max-width:1280px)": {
        fontSize: "1.1rem",
      },
      "@media (max-width:600px)": {
        fontSize: "0.875rem",
      },
    },
    h6: {
      textAlign: "center",
      marginTop: 40,
      marginBottom: 40,
      fontSize: "1.25rem",
      "@media (max-width:1280px)": {
        fontSize: "1rem",
      },
      "@media (max-width:600px)": {
        fontSize: "0.8125rem",
      },
    },
    subtitle1: {
      fontSize: "1.1rem",
      "@media (max-width:1280px)": {
        fontSize: "0.875rem",
      },
      "@media (max-width:600px)": {
        fontSize: "0.75rem",
      },
    },
    body1: {
      fontSize: "1.1rem",
      "@media (max-width:1280px)": {
        fontSize: "0.825rem",
      },
      "@media (max-width:600px)": {
        fontSize: "0.625rem",
      },
    },
  },
  palette: {
    primary: {
      // main: "#40739e",
      main: "#EE6741",
    },
    secondary: {
      main: "#8A8C90",
    },
  },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 900,
  //     lg: 1200,
  //     xl: 1536,
  //   },
  // },
});
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        TransitionComponent={Slide}
      >
        <TopBar />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
